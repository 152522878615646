import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "scenes/HomePage";
import RedirectToLanguage from "app/components/RedirectToLanguage";
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import fr from 'react-intl/locale-data/fr';
import de from 'react-intl/locale-data/de';
import pl from 'react-intl/locale-data/pl';
import {addLocaleData} from "react-intl";

addLocaleData([...en, ...es, ...it, ...fr, ...de, ...pl]);

const LandingMagliaApp = (props) => (
    <Router>
      <Switch>
        <Route path="/en/" component={HomePage}/>
        <Route path="/es/" component={HomePage}/>
        <Route path="/it/" component={HomePage}/>
        <Route path="/fr/" component={HomePage}/>
        <Route path="/de/" component={HomePage}/>
        <Route path="/pl/" component={HomePage}/>
        <Route component={RedirectToLanguage}/>
      </Switch>
    </Router>
);

export default LandingMagliaApp;