import React from "react";
import "./styles/FormSuccessLayer.scss";
import {FormattedMessage} from "react-intl";
import iconSuccess from "./img/icon-success.png";

const FormSuccessLayer = (props) => (
    <div className="form-success-layer">
      <div className="d-flex text-center align-items-center">
        <div className="form-success-layer-info">
          <h1>
            Mensaje enviado.
          </h1>
          <img src={iconSuccess} alt="Success"/>
          <h5 className="bold">
            Gracias por escribirnos.
          </h5>
          <h5>
            Pronto nos pondremos en contacto.
          </h5>
        </div>
      </div>
    </div>
);

export default FormSuccessLayer;
