import gql from "graphql-tag";

const magliaSubscribeToNewsletterMutation = gql`
    mutation CreateMagliaLead($input: MagliaSubscribeToNewsletterInput!) {
        magliaSubscribeToNewsletter(input: $input) {
            created
        }
    }
`;

export default magliaSubscribeToNewsletterMutation;