import React from "react";
import {Redirect} from "react-router-dom";

// const languages = ['en', 'es', 'it', 'fr', 'de', 'pl'];

const RedirectToLanguage = () => {
  // let lang = languages[0];
  // const navigatorLanguages = navigator.language.split(/[-_]/);
  //
  // if (languages.find(item => item === navigatorLanguages[0]))
  //   lang = navigatorLanguages[0];

  /*return (
      <Redirect to={`/${lang}`}/>
  )*/

  // Redirecting all users to /es by now because this is Mexico
  return (
      <Redirect to={`/es`}/>
  )
};

export default RedirectToLanguage;
