import gql from "graphql-tag";

const createMagliaLeadMutation = gql`
    mutation CreateMagliaLead($input: CreateMagliaLeadInput!) {
        createMagliaLead(input: $input) {
            created
        }
    }
`;

export default createMagliaLeadMutation;