import React from "react";
import {FormattedMessage} from "react-intl";
import {scroller} from "react-scroll";
import "./styles/Hero.scss";

const Hero = (props) => {
  const handleButtonClick = () => {
    scroller.scrollTo("requestForm", {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -50
    });
  };

  return (
      <section className="landing-hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="hero-content text-center">
                <h1 className="hero-content__title text-uppercase">
                  Maglia™
                </h1>
                <h3 className="hero-content__subtitle text-uppercase">
                  <FormattedMessage
                      id="Hero.subtitle"
                      description="Subtitle for Hero component"
                      defaultMessage="The new era in textile looking coated fabrics"/>
                </h3>
                <button
                    className="hero-content__button btn btn-lg btn-primary text-uppercase"
                    onClick={handleButtonClick}>
                  Contáctanos
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Hero;
