import React from "react";
import SpecsCard from "components/Specs/components/SpecsCard";
import {FormattedMessage} from "react-intl";
import iconAutomotive from "./img/icon-automotive.svg";
import iconContract from "./img/icon-contract.svg";
import iconMarine from "./img/icon-marine.svg";
import iconOutdoor from "./img/icon-outdoor.svg";
import imgBeautiful from "./img/beautiful.jpg";
import imgHighSpec from "./img/high-spec.jpg";
import imgStyle from "./img/style.jpg";
import "./styles/Specs.scss";
import TitleWithDivider from "utils/components/TitleWithDivider";

const Specs = (props) => (
    <section className="specs pt-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <SpecsCard
                backgroundImage={imgStyle}
                title={
                  <FormattedMessage
                      id="Specs.styleCardTitle"
                      description="Title for style card in Specs component"
                      defaultMessage="Style for the senses"/>
                }
                description={
                  <span>
                    Inspirada en el reconocido diseño tipo “Oxford”, nuestro nuevo tejido MAGLIATM se lanza al mercado
                    para ofrecer una apariencia natural propia de los tejidos de fibras de hilo, brindando confort tanto
                    en ambientes exteriores como interiores.
                  </span>
                }/>
          </div>
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <SpecsCard
                backgroundImage={imgBeautiful}
                title={
                  <FormattedMessage
                      id="Specs.beautifulCardTitle"
                      description="Title for beautiful card in Specs component"
                      defaultMessage="Beautiful & Resistant"/>
                }
                description={
                  <span>
                    Mientras los colores y textura hacen de MAGLIA™ un producto único, su excelente calidad garantiza
                    durabilidad y resistencia. Contiene tecnología PERMABLOK3® que actúa como una barrera protectora
                    contra gérmenes, rasgaduras y manchas, y garantiza una protección inteligente que cuida el
                    mobiliario.
                  </span>
                }/>
          </div>
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <SpecsCard
                backgroundImage={imgHighSpec}
                title={
                  <FormattedMessage
                      id="Specs.highCardTitle"
                      description="Title for high card in Specs component"
                      defaultMessage="High Specification"/>
                }
                description={
                  <span>
                    Es un producto con certificación IMO, MED, EN 1021 1&2 y M2. Tiene propiedades impermeables y es
                    libre de FTALATOS. MAGLIA™ es el producto idónea para cubrir las más sofisticadas creaciones.
                  </span>
                }/>
          </div>
        </div>
        <div className="specs-suitable-for">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-3 d-lg-flex justify-content-lg-center align-items-lg-center">
              <TitleWithDivider
                  title={
                    <span>
                      <FormattedMessage
                          id="Specs.suitableForTitle"
                          description="Suitable for title in Specs component"
                          defaultMessage="Suitable for"/>:
                    </span>}
                  color="gray"/>
            </div>
            <div className="col-12 col-lg-7">
              <div className="row no-gutters">
                <div className="col-3">
                  <div className="specs-icon">
                    <div className="specs-icon__image">
                      <img src={iconContract} alt=""/>
                    </div>
                    <h5 className="specs-icon__name">
                      <FormattedMessage
                          id="Specs.contract"
                          description="Contract icon name in Specs component"
                          defaultMessage="Contract"/>
                    </h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="specs-icon">
                    <div className="specs-icon__image">
                      <img src={iconOutdoor} alt=""/>
                    </div>
                    <h5 className="specs-icon__name">
                      <FormattedMessage
                          id="Specs.outdoor"
                          description="Outdoor icon name in Specs component"
                          defaultMessage="Outdoor"/>
                    </h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="specs-icon">
                    <div className="specs-icon__image">
                      <img src={iconMarine} alt=""/>
                    </div>
                    <h5 className="specs-icon__name">
                      <FormattedMessage
                          id="Specs.marine"
                          description="Marine icon name in Specs component"
                          defaultMessage="Marine"/>
                    </h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="specs-icon">
                    <div className="specs-icon__image">
                      <img src={iconAutomotive} alt=""/>
                    </div>
                    <h5 className="specs-icon__name">
                      <FormattedMessage
                          id="Specs.automotive"
                          description="Automotive icon name in Specs component"
                          defaultMessage="Automotive"/>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
);

export default Specs;
