import React from 'react';
import Header from "components/Header";
import Hero from "components/Hero";
import Specs from "components/Specs";
import RequestForm from "components/RequestForm";
import ColourRange from "components/ColourRange";
import Footer from "components/Footer";
import {IntlProvider} from "react-intl";
import esMessages from "locales/es";
import enMessages from "locales/en";
import itMessages from "locales/it";
import frMessages from "locales/fr";
import deMessages from "locales/de";
import plMessages from "locales/pl";
import "./styles/HomePage.scss";

const messages = {
  es: esMessages,
  en: enMessages,
  it: itMessages,
  fr: frMessages,
  de: deMessages,
  pl: plMessages,
};

const HomePage = ({match, location}) => {
  const lang = location.pathname.substring(1,3);

  return (
      <IntlProvider
          messages={messages[lang]}
          locale={lang}>
        <div className="landing-maglia-app">
          <Header/>
          <Hero/>
          <Specs/>
          <div className="home-color-request">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-4 order-lg-1">
                  <RequestForm/>
                </div>
                <div className="col-12 col-lg-8 order-lg-0">
                  <ColourRange/>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </IntlProvider>
  )
};

export default HomePage;