import React from "react";
import PropTypes from "prop-types";
import iconTimes from "./img/icon-times.svg";
import "./styles/SelectedColor.scss";

const SelectedColor = ({product, onCloseButtonClick}) => {
  return (
      <div
          className="selected-color"
          style={{
            backgroundImage: `url(${product.imageThumbMedium})`
          }}>
        <div className="selected-color__header">
          <button
              type="button"
              onClick={onCloseButtonClick}>
            <img src={iconTimes} alt="close"/>
          </button>
        </div>
        <div className="selected-color__footer d-flex justify-content-center align-items-center flex-column">
          <h4>
            {product.name}
          </h4>
        </div>
      </div>
  )
};

SelectedColor.propTypes = {
  product: PropTypes.object.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired
};

export default SelectedColor;
