import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import logo from "./img/spradling-logo-landscape.svg";
import "./styles/Footer.scss";
import NewsletterForm from "components/Footer/components/NewsletterForm";

const Footer = () => (
    <footer className="landing-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 mb-5">
            <nav className="nav flex-column">
              <a className="nav-link" href="http://www.spradling.group/politicaPrivacidad/" target="_blank" rel="noreferrer noopener">
                <FormattedMessage
                    id="Footer.privacyPolicy"
                    description="Privacy Policy link text in Footer"
                    defaultMessage="Privacy Policy"/>
              </a>
            </nav>
          </div>
          <div className="col-12 col-lg-4 mb-5">
            <div className="footer-newsletter">
              <h5 className="footer-newsletter__title text-uppercase">
                <FormattedMessage
                    id="Footer.newsletter"
                    description="Newsletter section title in Footer"
                    defaultMessage="Newsletter"/>
              </h5>
              <NewsletterForm/>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-5">
            <h5 className="text-uppercase">
              <FormattedMessage
                  id="Footer.socialNetworks"
                  description="Social Networks section title in Footer"
                  defaultMessage="Social Networks"/>
            </h5>
            <p className="footer-social-links">
              <a href="https://facebook.com/spradlingmexico" target="_blank" rel="noreferrer noopener">
                <i className="fa fa-fw fa-facebook"/> Facebook
              </a>
            </p>
          </div>
        </div>
        <div className="footer-logo-and-copy text-center">
          <img src={logo} alt="Spradling Group"/>
          <p className="text-uppercase">
            &copy; {new Date().getFullYear()} SPRADLING MEXICO
          </p>
        </div>
      </div>
    </footer>
);

export default injectIntl(Footer);
