import React, {Component} from "react";
import classNames from 'classnames';
import {defineMessages, FormattedMessage, injectIntl} from "react-intl";
import createMagliaLeadMutation from "components/RequestForm/mutation";
import {graphql} from 'react-apollo'
import {withRouter} from "react-router-dom";
import "./styles/RequestForm.scss";
import {Element} from "react-scroll";
import FormSuccessLayer from "components/RequestForm/components/FormSuccessLayer";

class RequestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: '',
        surname: '',
        company: '',
        position: '',
        email: '',
        phone: '',
        message: ''
      },
      loading: false,
      success: null
    };

    this.handleTextInputChange = field => this._handleTextInputChange.bind(this, field);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Handle changes to all form inputs and update the state with the new data
   * @param field
   * @param event
   * @private
   */
  _handleTextInputChange(field, event) {
    const value = event.target.value;
    this.setState({
      form: {
        ...this.state.form,
        [field]: value
      }
    });
  }

  /**
   * Handle form submit and calls createMagliaLeadMutation mutation
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      loading: true
    });

    const {location} = this.props;
    const {form} = this.state;

    this.props.createMagliaLeadMutation({
      variables: {
        input: {
          ...form,
          // recipient: 'cristianguillermorojas@gmail.com',
          recipient: 'servicioalclientemx@spradling.group',
          lang: location.pathname.substring(1, 3),
          mexico: 'True'
        }
      }
    }).then(response => {
      this.resetFormData();
      this.setState({
        loading: false,
        success: true
      })
    }).catch(error => {
      // this.resetFormData();
      this.setState({
        loading: false,
        success: false
      })
    })
  }

  /**
   * Reset all the state data to restart the form
   */
  resetFormData() {
    this.setState({
      form: {
        name: '',
        surname: '',
        company: '',
        position: '',
        email: '',
        phone: '',
        message: '',
      }
    })
  }

  render() {
    const {formatMessage} = this.props.intl;
    const {name, surname, company, position, email, phone, message} = this.state.form;
    const {loading, success} = this.state;

    return (
        <Element name="requestForm">
          <section className="request-form mb-5">
            <div className="container">
              <h2>
                <FormattedMessage
                    id="RequestForm.title"
                    description="Title for RequestForm component"
                    defaultMessage="Are you ready to become a part of this new era in Coated Fabrics?"/>
              </h2>
              <h3>
                ¡Solicita más información sobre MAGLIA™ aquí!
              </h3>
              <div className="maglia-form-wrapper">
                <form className="request-maglia-form" onSubmit={this.handleSubmit}>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": name !== ''
                  })}>
                    <label htmlFor="name">
                      <FormattedMessage
                          id="RequestForm.nameFormField"
                          description="Name field of the RequestForm"
                          defaultMessage="Name"/>
                    </label>
                    <input
                        value={name}
                        onChange={this.handleTextInputChange('name')}
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            nameFieldPlaceholder: {
                              id: "RequestForm.nameFieldPlaceholder",
                              description: "Placeholder to the Name field",
                              defaultMessage: "Name",
                            }
                          }).nameFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": surname !== ''
                  })}>
                    <label htmlFor="surname">
                      <FormattedMessage
                          id="RequestForm.surnameFormField"
                          description="Surname field of the RequestForm"
                          defaultMessage="Surname"/>
                    </label>
                    <input
                        value={surname}
                        onChange={this.handleTextInputChange('surname')}
                        type="text"
                        className="form-control"
                        id="surname"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            surnameFieldPlaceholder: {
                              id: "RequestForm.surnameFieldPlaceholder",
                              description: "Placeholder to the Surname field",
                              defaultMessage: "Surname",
                            }
                          }).surnameFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": company !== ''
                  })}>
                    <label htmlFor="company">
                      <FormattedMessage
                          id="RequestForm.companyFormField"
                          description="Company field of the RequestForm"
                          defaultMessage="Company"/>
                    </label>
                    <input
                        value={company}
                        onChange={this.handleTextInputChange('company')}
                        type="text"
                        className="form-control"
                        id="company"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            companyFieldPlaceholder: {
                              id: "RequestForm.companyFieldPlaceholder",
                              description: "Placeholder to the Company field",
                              defaultMessage: "Company",
                            }
                          }).companyFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": position !== ''
                  })}>
                    <label htmlFor="position">
                      <FormattedMessage
                          id="RequestForm.positionFormField"
                          description="Position field of the RequestForm"
                          defaultMessage="Position"/>
                    </label>
                    <input
                        value={position}
                        onChange={this.handleTextInputChange('position')}
                        type="text"
                        className="form-control"
                        id="position"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            positionFieldPlaceholder: {
                              id: "RequestForm.positionFieldPlaceholder",
                              description: "Placeholder to the Position field",
                              defaultMessage: "Position",
                            }
                          }).positionFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": email !== ''
                  })}>
                    <label htmlFor="email">
                      <FormattedMessage
                          id="RequestForm.emailFormField"
                          description="Email field of the RequestForm"
                          defaultMessage="Email"/>
                    </label>
                    <input
                        value={email}
                        onChange={this.handleTextInputChange('email')}
                        type="email"
                        className="form-control"
                        id="email"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            emailFieldPlaceholder: {
                              id: "RequestForm.emailFieldPlaceholder",
                              description: "Placeholder to the Email field",
                              defaultMessage: "Email",
                            }
                          }).emailFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": phone !== ''
                  })}>
                    <label htmlFor="phone">
                      <FormattedMessage
                          id="RequestForm.phoneFormField"
                          description="Phone field of the RequestForm"
                          defaultMessage="Phone"/>
                    </label>
                    <input
                        value={phone}
                        onChange={this.handleTextInputChange('phone')}
                        type="number"
                        className="form-control"
                        id="phone"
                        required
                        placeholder={
                          formatMessage(defineMessages({
                            phoneFieldPlaceholder: {
                              id: "RequestForm.phoneFieldPlaceholder",
                              description: "Placeholder to the Phone field",
                              defaultMessage: "Phone",
                            }
                          }).phoneFieldPlaceholder)
                        }/>
                  </div>
                  <div className={classNames({
                    "form-group": true,
                    "form-group--filled": message !== ''
                  })}>
                    <label htmlFor="message">
                      <FormattedMessage
                          id="RequestForm.messageFormField"
                          description="Message field of the RequestForm"
                          defaultMessage="Message"/>
                    </label>
                    <textarea
                        className="form-control"
                        id="message"
                        value={message}
                        required
                        onChange={this.handleTextInputChange('message')}
                        rows={3}
                        placeholder={
                          formatMessage(defineMessages({
                            messageFieldPlaceholder: {
                              id: "RequestForm.messageFieldPlaceholder",
                              description: "Placeholder to the Message field",
                              defaultMessage: "Message",
                            }
                          }).messageFieldPlaceholder)
                        }/>
                  </div>
                  <button
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase"
                      disabled={loading}>
                    {
                      loading
                          ?
                          <FormattedMessage
                              id="RequestForm.submitButtonLoadingText"
                              description="Loading text for submit button of the RequestForm"
                              defaultMessage="Sending..."/>
                          :
                          <FormattedMessage
                              id="RequestForm.submitButtonText"
                              description="Submit button of the RequestForm"
                              defaultMessage="Send"/>
                    }
                  </button>
                </form>
                {
                  success === true && <FormSuccessLayer/>
                }
              </div>
            </div>
          </section>
        </Element>
    )
  }
}

export default injectIntl(
    withRouter(
        graphql(createMagliaLeadMutation, {name: 'createMagliaLeadMutation'})(RequestForm)
    )
);
