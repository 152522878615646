import React, {Component} from "react";
import TitleWithDivider from "utils/components/TitleWithDivider";
import {FormattedMessage} from "react-intl";
import "./styles/ColourRange.scss";
import ColourCard from "components/ColourRange/components/ColourCard";
import SelectedColor from "components/ColourRange/components/SelectedColor";
import colors from "./data/colors";

class ColourRange extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedColor: null
    };

    this.handleColorClick = color => this._handleColorClick.bind(this, color);
    this.handleSelectedColorCloseButtonClick = this.handleSelectedColorCloseButtonClick.bind(this);
  }

  /**
   * Handles clicks on the color card component
   * @param color
   */
  _handleColorClick(color) {
    this.setState({
      selectedColor: color
    })
  }

  /**
   * Handles click on the selected color component's close button
   * @param event
   */
  handleSelectedColorCloseButtonClick(event) {
    this.setState({
      selectedColor: null
    })
  }

  render() {
    const {selectedColor} = this.state;

    return (
        <section className="colour-range mb-5">
          <TitleWithDivider
              title={
                <FormattedMessage
                    id="ColourRange.title"
                    description="Title for ColourRange component"
                    defaultMessage="Colour Range"/>}/>
          <p>
            Siguiendo las tendencias globales hemos creado 16 tonos increíbles que despiertan los sentidos:
          </p>
          <div className="colour-cards">
            <div className="row">
              {
                colors.map((color, index) =>
                    <div
                        key={index}
                        className="col-4 col-md-3 col-lg-2">
                      <ColourCard
                          product={color}
                          onClick={this.handleColorClick(color)}/>
                    </div>
                )
              }
            </div>
            {
              selectedColor &&
              <SelectedColor
                  product={selectedColor}
                  onCloseButtonClick={this.handleSelectedColorCloseButtonClick}/>
            }
          </div>
        </section>
    )
  }
}

export default ColourRange;
